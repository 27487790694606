import store from '../../../../../../store';
import Employee from '../../../../admin/employee';
import { format } from 'date-fns';
export default {
  name: 'sendForApproval',
  components: { Employee },
  props: ['wfHeaderId', 'eventId'],
  data() {
    return {
      showEmpModal: false,
      approverListIndex: null,
      eventIdFromMenu: null,
      loader: false,
      editMode: false,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      historyList: [],
      historyFields: [
        {
          key: 'approver_name',
          label: 'Approver'
        },
        {
          key: 'approver_designation',
          label: 'Designation'
        },
        {
          key: 'action_date'
        },
        {
          key: 'initiation_date',
          label: 'Init Date'
        },
        {
          key: 'seq_order'
        },
        {
          key: 'approval_status',
          label: 'Status'
        },
        {
          key: 'information_only',
          label: 'Info Only'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ]
    };
  },
  mounted() {
    if (this.eventId) {
      this.getHistory(this.eventId);
    } else {
      this.getEventId();
    }
  },
  methods: {
    getEventId() {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getEventId', store.state.shared.menuId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.eventIdFromMenu = response.data.data;
            this.getHistory(response.data.data);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getHistory(eventId) {
      this.loader = true;
      const payload = {
        event_id: eventId,
        record_id: this.wfHeaderId
      };
      this.$store
        .dispatch('intWorkflow/processWorkflow', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.historyList = response.data.data;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.respMessage = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addNewRow(index) {
      this.historyList.splice(index, 0, {
        comments: null,
        seq_order: null,
        wf_inprocess_hdr_id: null,
        information_only: false,
        delegated_employee_designation: null,
        approver_employee_id: null,
        wf_inprocess_dtl_id: 0,
        approver_employee_num: null,
        approver_designation: null,
        delegated_employee_id: null,
        delegated_employee_num: null,
        approver_name: null,
        approval_status: null,
        initiation_date: null,
        action_date: null,
        delegated_employee_name: null
      });
      this.historyList = this.historyList.map((data, index) => {
        data.seq_order = index + 1;
        return data;
      });
    },
    removeRow(index) {
      this.historyList.splice(index, 1);
      this.historyList = this.historyList.map((data, index) => {
        data.seq_order = index + 1;
        return data;
      });
    },
    sendForApproval() {
      const filterData = this.historyList;
      if (filterData.length > 0) {
        const approverDetails = filterData.map(data => {
          return {
            approver_employee_id: data.approver_employee_id,
            information_only: data.information_only,
            seq_order: data.seq_order,
            wf_inprocess_dtl_id: data.wf_inprocess_dtl_id,
            wf_inprocess_hdr_id: data.wf_inprocess_hdr_id
          };
        });
        if (approverDetails && approverDetails.length > 0) {
          const payload = {
            approval_details: approverDetails,
            event_id: this.eventId ? this.eventId : this.eventIdFromMenu,
            record_id: this.wfHeaderId
          };
          this.loader = true;
          this.$store
            .dispatch('intWorkflow/getWrkflowApproval', payload)
            .then(response => {
              this.loader = false;
              this.showAlert = true;
              if (response.status === 200) {
                this.$emit('updateList');
                this.editMode = false;
                this.isSuccess = true;
                this.respMessage = response.data.message;
                this.historyList = this.historyList.map(data => {
                  data.wf_inprocess_dtl_id = null;
                  return data;
                });
                if(this.eventIdFromMenu==35){
                  this.$emit('getBatchAssetLiqDetails',this.eventIdFromMenu)
                }
                else if(this.eventId==36){
                  this.$emit('getRemedyHeader',this.eventId)
                }
              } else {
                this.isSuccess = false;
                this.respMessage = response.response.data.message;
              }
            })
            .catch(() => {
              this.loader = false;
            });
        }
      }
    },
    openEmpModal(index) {
      this.approverListIndex = index;
      this.showEmpModal = true;
    },
    employeeDetails(empData) {
      this.historyList[this.approverListIndex].approver_name = empData.name;
      this.historyList[this.approverListIndex].approver_designation =
        empData.designation_vset;
      this.historyList[this.approverListIndex].initiation_date = format(
        new Date(),
        'dd-MMM-yyyy'
      );
      this.historyList[this.approverListIndex].approver_employee_id =
        empData.employee_id;
      this.historyList[this.approverListIndex].seq_order =
        this.approverListIndex + 1;
      this.historyList[this.approverListIndex].approval_status = 'DRAFT';
      this.historyList[this.approverListIndex].wf_inprocess_dtl_id = 0;
      this.historyList[
        this.approverListIndex
      ].wf_inprocess_hdr_id = this.historyList[0].wf_inprocess_hdr_id;
      this.showHideEmpModal();
    },
    showHideEmpModal() {
      this.showEmpModal = false;
    },
    close() {}
  }
};
