import commonHelper from '@/app/utility/common.helper.utility';
import advanceSearch from '../mutation/advanceSearch';
import addEditMutation from '../mutation/addEditMutation';
import appStrings from '@/app/utility/string.utility.js';
import HistoryRecord from '@/app/components/onlineApproval/internalWorkFlow/history/historyRecord';
import ApprovalHistory from '@/app/components/onlineApproval/internalWorkFlow/history/approvalHistory';
import UploadDocument from '@/app/components/onlineApproval/internalWorkFlow/history/uploadDocument';
import sendForApproval from '@/app/components/onlineApproval/internalWorkFlow/history/sendForApproval';
import openKmAuthentication from '@/app/components/onlineApproval/internalWorkFlow/openKmAuthentication';
import DatePicker from 'vue2-datepicker';
import { required } from 'vuelidate/lib/validators';
import { ModelSelect } from 'vue-search-select';
export default {
  name: 'mutation',
  components: {
    advanceSearch,
    addEditMutation,
    HistoryRecord,
    ApprovalHistory,
    UploadDocument,
    sendForApproval,
    openKmAuthentication,
    DatePicker,
    ModelSelect
  },
  watch: {
    currentPage: function() {
      this.getMuationList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getMuationList();
    }
  },
  data() {
    return {
      payload: null,
      // unsubscribe: null,
      currentPage: 1,
      isDependent: false,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      loader: false,
      showMutationModal: false,
      mutation: null,
      mutationNum: null,
      mutationFields: [
        {
          key: 'select',
          stickyColumn: true
        },
        {
          key: 'le_name',
          label: 'Legal Entity'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'mutation_num',
          label: 'Mutation No.'
        },
        {
          key: 'mutation_type_vset_meaning',
          label: 'Mutation Type'
        },
        {
          key: 'trx_type',
          label: 'Transaction Type'
        },
        {
          key: 'fms_prj_name',
          label: 'FMS Project'
        },
        {
          key: 'fms_tower_name',
          label: 'FMS Tower'
        },
        {
          key: 'fms_floor_name',
          label: 'FMS Floor'
        },
        {
          key: 'fms_unit_name',
          label: 'FMS Unit'
        },
        {
          key: 'mutation_date'
        },
        {
          key: 'transfer_date'
        },
        {
          key: 'transfer_status_meaning',
          label: 'Transfer Status'
        },
        {
          key: 'approval_status_meaning',
          label: 'Approval Status'
        }
      ],
      mutationData: [],
      advanceSearchModal: false,
      addEditMutationModal: false,
      mutationRowData: null,
      leName: null,
      leId: null,
      ouName: null,
      ouId: null,
      fmsPrjName: null,
      fmsPrjId: null,
      fmsTowerName: null,
      fmsTowerId: null,
      fmsFloorName: null,
      fmsFloorId: null,
      fmsUnitName: null,
      fmsUnitId: null,
      showValueSetModal: false,
      parent_value_set_id: null,

      showHistoryBtn: false,
      showHistory: false,
      historyType: null,
      showOpenKmModal: false,
      openkmAuthToken: null,

      selectedMutationDetails: null,
      selectedMutationHdrId: null,

      mutationFee: null,
      approvalStatus: null,
      approvalStatusMeaning: null,
      transferStatus: null,
      transferStatusMeaning: null,
      mutationTypeVset: null,
      mutationTypeVsetMeaning: null,
      selectedTrxType: {
        value: null,
        text: null
      },
      trxTypeList: [],
      mutationDate: [],
      transferDate: [],
      selectedGridData: null,
      rowDetails: null
    };
  },
  validations: {
    leName: { required }
  },
  mounted() {
    this.getTxnTypeList();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.selectedMutationHdrId = null;
          this.addEditMutationModal = true;
          this.rowDetails = null;
        }
      }
    });
  },
  methods: {
    getMuationList() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          pagination: {
            _page: this.currentPage - 1,
            _limit: this.perPage
          },
          searchParams: {
            approval_status: this.approvalStatus,
            fms_unit_id: this.fmsUnitId,
            le_id: this.leId,
            mutation_date_from: commonHelper.formattedDate(
              this.mutationDate[0]
            ),
            mutation_date_to: commonHelper.formattedDate(this.mutationDate[0]),
            mutation_fee: this.mutationFee,
            mutation_num: this.mutationNum,
            mutation_type_vset: this.mutationTypeVset,
            ou_id: this.ouId,
            transfer_date_from: commonHelper.formattedDate(
              this.transferDate[0]
            ),
            transfer_date_to: commonHelper.formattedDate(this.transferDate[1]),
            transfer_status: this.transferStatus,
            trx_type_dtl_id: this.selectedTrxType.value,
            fms_prj_id: this.fmsPrjId
          }
        };
        this.loader = true;
        this.$store
          .dispatch('masterData/getMuationList', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.mutationData = response.data.data.page;
              this.totalRows = response.data.data.total_elements;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    clearSearch() {
      this.leName = null;
      this.leId = null;
      this.ouName = null;
      this.ouId = null;
      this.fmsPrjName = null;
      this.fmsPrjId = null;
      this.fmsTowerName = null;
      this.fmsTowerId = null;
      this.fmsFloorName = null;
      this.fmsFloorId = null;
      this.fmsUnitName = null;
      this.fmsUnitId = null;
      this.mutationData = [];
      this.showHistoryBtn = false;
      this.mutationNum = null;
      this.mutationFee = null;
      this.approvalStatus = null;
      this.approvalStatusMeaning = null;
      this.transferStatus = null;
      this.transferStatusMeaning = null;
      this.mutationTypeVset = null;
      this.mutationTypeVsetMeaning = null;
      this.selectedTrxType = {
        value: null,
        text: null
      };
      this.mutationDate = [];
      this.transferDate = [];
      this.totalRows = null;
      this.currentPage = 1;
    },
    showHideAdvanceSearch() {
      this.advanceSearchModal = false;
    },
    showHideAddEditMutation() {
      this.addEditMutationModal = false;
    },
    openCloseMutationDetails(flag) {
      this.addEditMutationModal = flag;
    },
    mutationRowSelected(item) {
      this.selectedMutationHdrId = item.fms_mutation_hdr_id;
      this.rowDetails = item;
      this.openCloseMutationDetails(true);
    },
    openValueSetModal(vsetCode) {
      this.chooseParentVsetId(vsetCode);
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      switch (this.vsetCode) {
        case appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST:
          this.leName = item.value_code;
          this.leId = item.value_set_dtl_id;
          this.ouName = null;
          this.ouId = null;
          break;
        case appStrings.VALUESETTYPE.OU_LIST:
          this.ouName = item.value_code;
          this.ouId = item.value_set_dtl_id;
          break;
        case appStrings.VALUESETTYPE.FMS_PROJECT:
          this.fmsPrjName = item.value_code;
          this.fmsPrjId = item.value_set_dtl_id;
          break;
        case appStrings.VALUESETTYPE.NFA_FMS_TOWER:
          this.fmsTowerName = item.value_code;
          this.fmsTowerId = item.value_set_dtl_id;
          break;
        case appStrings.VALUESETTYPE.NFA_FMS_FLOOR:
          this.fmsFloorName = item.value_code;
          this.fmsFloorId = item.value_set_dtl_id;
          break;
        case appStrings.VALUESETTYPE.NFA_FMS_UNIT:
          this.fmsUnitName = item.value_code;
          this.fmsUnitId = item.value_set_dtl_id;
          break;
        case appStrings.VALUESETTYPE.MUTATION_TYPE:
          this.mutationTypeVset = item.value_code;
          this.mutationTypeVsetMeaning = item.value_meaning;
          break;
        case appStrings.VALUESETTYPE.SCHEDULE_STATUS_VSET:
          this.transferStatus = item.value_code;
          this.transferStatusMeaning = item.value_meaning;
          break;
        case appStrings.VALUESETTYPE.APPROVAL_STATUS:
          this.approvalStatus = item.value_code;
          this.approvalStatusMeaning = item.value_meaning;
          break;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    chooseValueset(name) {
      switch (name) {
        case 'legal_entity':
          this.openValueSetModal(appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST);
          break;
        case 'operating_unit':
          this.openValueSetModal(appStrings.VALUESETTYPE.OU_LIST);
          break;
        case 'mutation_type':
          this.openValueSetModal(appStrings.VALUESETTYPE.MUTATION_TYPE);
          break;
        case 'fms_project':
          this.openValueSetModal(appStrings.VALUESETTYPE.FMS_PROJECT);
          break;
        case 'fms_tower':
          this.openValueSetModal(appStrings.VALUESETTYPE.NFA_FMS_TOWER);
          break;
        case 'fms_floor':
          this.openValueSetModal(appStrings.VALUESETTYPE.NFA_FMS_FLOOR);
          break;
        case 'fms_unit':
          this.openValueSetModal(appStrings.VALUESETTYPE.NFA_FMS_UNIT);
          break;
        case 'transfer_status':
          this.openValueSetModal(appStrings.VALUESETTYPE.SCHEDULE_STATUS_VSET);
          break;
        case 'approval_status':
          this.openValueSetModal(appStrings.VALUESETTYPE.APPROVAL_STATUS);
          break;
      }
    },
    chooseParentVsetId(name) {
      switch (name) {
        case appStrings.VALUESETTYPE.OU_LIST:
        case appStrings.VALUESETTYPE.FMS_PROJECT:
          this.parent_value_set_id = this.leId;
          break;
        case appStrings.VALUESETTYPE.NFA_FMS_TOWER:
          this.parent_value_set_id = this.fmsPrjId;
          break;
        case appStrings.VALUESETTYPE.NFA_FMS_FLOOR:
          this.parent_value_set_id = this.fmsTowerId;
          break;
        case appStrings.VALUESETTYPE.NFA_FMS_UNIT:
          this.parent_value_set_id = this.fmsFloorId;
          this.isDependent = true;
          break;
        default:
          this.parent_value_set_id = null;
      }
    },
    showHideHistory(flag, historyType) {
      this.showHistory = flag;
      this.historyType = historyType;
    },
    async isDocumentUploadedFunc() {
      const uploadedDocList = await commonHelper.getUploadedDocList(
        this.$store.state.shared.menuId,
        this.selectedMutationHdrId
      );
      const isDocumentUploaded = uploadedDocList.length > 0;
      isDocumentUploaded
        ? this.showHideHistory(true, 'sendForApproval')
        : alert('Please upload document!');
    },
    validateUploadDocument() {
      if (this.selectedMutationDetails.approval_status === 'DRAFT') {
        this.isDocumentUploadedFunc();
      } else {
        this.showHideHistory(true, 'sendForApproval');
      }
    },
    checkOpenKmPasswordSaved() {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/checkSavedPassword')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data) {
              this.getOpenKmAuthToken();
            } else {
              this.showHideOpenKmModal(true);
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getOpenKmAuthToken() {
      const payload = {
        password: null,
        saved_password_flag: true,
        user_name: null
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmAuthenticate', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openkmAuthToken = response.data.data.token;
            this.selectedGridData = {
              ...this.selectedMutationDetails,
              nfa_cat_vset: null,
              nfa_cat: null,
              nfa_sub_cat_vset: null,
              nfa_sub_cat: null
            };
            this.showHideHistory(true, 'uploadDocument');
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideOpenKmModal(flag) {
      this.showOpenKmModal = flag;
    },
    setOpenKmToken(token) {
      this.showHideOpenKmModal(false);
      this.openkmAuthToken = token;
      this.selectedGridData = {
        ...this.selectedMutationDetails,
        nfa_cat_vset: null,
        nfa_cat: null,
        nfa_sub_cat_vset: null,
        nfa_sub_cat: null
      };
      this.showHideHistory(true, 'uploadDocument');
    },
    selectedMutation(item) {
      this.selectedMutationDetails = Object.assign({}, item);
      this.showHistoryBtn = true;
      this.selectedMutationHdrId = item.fms_mutation_hdr_id;
    },
    setMutationDetails(item) {
      this.selectedMutationDetails = Object.assign({}, item);
      this.selectedMutationHdrId = item.fms_mutation_hdr_id;
    },
    setMutationDetailsId(id) {
      this.selectedMutationHdrId = id;
    },
    getTxnTypeList() {
      const moduleId = this.$store.state.shared.moduleId;
      this.$store.dispatch('leases/getTxnTypeList', moduleId).then(response => {
        if (response.status === 200) {
          const results = response.data.data;
          const typeList = results.map(type => {
            return {
              value: type.trx_type_dtl_id,
              text: type.trx_desc
            };
          });
          this.trxTypeList = typeList;
        }
      });
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === 'legal_entity') {
        this.leName = null;
        this.leId = null;
        this.ouName = null;
        this.ouId = null;
      } else if (vsetCode === 'operating_unit') {
        this.ouName = null;
        this.ouId = null;
      } else if (vsetCode === 'fms_project') {
        this.fmsPrjName = null;
        this.fmsPrjId = null;
      } else if (vsetCode === 'fms_unit') {
        this.fmsUnitName = null;
        this.fmsUnitId = null;
      } else if (vsetCode === 'mutation_type') {
        this.mutationTypeVset = null;
        this.mutationTypeVsetMeaning = null;
      } else if (vsetCode === 'transfer_status') {
        this.transferStatus = null;
        this.transferStatusMeaning = null;
      } else if (vsetCode === 'approval_status') {
        this.approvalStatus = null;
        this.approvalStatusMeaning = null;
      }
    }
  }
};
