import appStrings from '@/app/utility/string.utility.js';
import Datepicker from 'vue2-datepicker';
export default {
  name: 'AdvanceSearch',
  components: {
    Datepicker
  },
  data() {
    return {
      currentCustomer: null,
      customerSite: null,
      customerVendor: null,
      vendorSite: null,
      transferDate: [],
      previousCustomer: null,
      previousVendor: null,
      mutationNum: '',
      customer_sharing_ratio: null,
      vendor_sharing_ratio: null,

      customerId: null,
      customerSiteId: null,
      fmsFloorId: null,
      fmsProjectId: null,
      fmsTowerId: null,
      fmsUnitId: null,
      sharingRatio: null,
      vendorId: null,
      vendorSiteId: null,

      showValueSetModal: false,
      parent_value_set_id: null,
      mutationTypeVset: null,
      mutationTypeVsetMeaning: null,
      
      approvalStatus: null,
      approvalStatusMeaning: null
    };
  },
  methods: {
    searchAdvanceFilters() {
      // const payload = {
      //   approval_status: this.approvalStatus,
      //   customer_id: this.customerId,
      //   customer_site_id: this.customerSiteId,
      //   fms_floor_id: this.fmsFloorId,
      //   fms_prj_id: this.fmsProjectId,
      //   fms_tower_id: this.fmsTowerId,
      //   fms_unit_id: this.fmsUnitId,
      //   mutation_num: this.mutationNum,
      //   mutation_type_vset: this.mutationTypeVset,
      //   sharing_ratio: this.sharingRatio,
      //   transfer_date_from: this.transferDate[0],
      //   transfer_date_to: this.transferDate[1],
      //   vendor_id: this.vendorId,
      //   vendor_site_id: this.vendorSiteId
      // };
      
    },
    clearSearch() {
      this.currentCustomer = null;
      this.customerSite = null;
      this.customerVendor = null;
      this.vendorSite = null;
      this.mutationType = null;
      this.transferDate = null;
      this.transfer_date_to = null;
      this.previousCustomer = null;
      this.customerSite = null;
      this.previousVendor = null;
      this.vendor_site = null;
      this.approval_status = null;
      this.mutation_no = null;
      this.customer_sharing_ratio = null;
      this.vendor_sharing_ratio = null;
    },
    openValueSetModal(vsetCode) {
      this.chooseParentVsetId(vsetCode);
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      switch (this.vsetCode) {
        case appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST:
          this.leName = item.value_code;
          this.leId = item.value_set_dtl_id;
          this.ouName = null;
          this.ouId = null;
          this.fmsPrjName = null;
          this.fmsPrjId = null;
          break;
        case appStrings.VALUESETTYPE.OU_LIST:
          this.ouName = item.value_code;
          this.ouId = item.value_set_dtl_id;
          break;
        case appStrings.VALUESETTYPE.FMS_PROJECT:
          this.fmsPrjName = item.value_code;
          this.fmsPrjId = item.value_set_dtl_id;
          break;
        case appStrings.VALUESETTYPE.FMS_TOWER:
          this.fmsTowerName = item.value_meaning;
          this.fmsTowerId = item.value_code;
          break;
        case appStrings.VALUESETTYPE.FMS_FLOOR:
          this.fmsFloorName = item.value_meaning;
          this.fmsFloorId = item.value_code;
          break;
        case appStrings.VALUESETTYPE.FMS_UNIT:
          this.fmsUnitName = item.value_meaning;
          this.fmsUnitId = item.value_code;
          break;
        case appStrings.VALUESETTYPE.MUTATION_TYPE:
          this.mutationTypeVset = item.value_code;
          this.mutationTypeVsetMeaning = item.value_meaning;
          break;
        case appStrings.VALUESETTYPE.SCHEDULE_STATUS_VSET:
          this.transferStatus = item.value_code;
          this.transferStatusMeaning = item.value_meaning;
          break;
        case appStrings.VALUESETTYPE.APPROVAL_STATUS:
          this.approvalStatus = item.value_code;
          this.approvalStatusMeaning = item.value_meaning;
          break;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    chooseValueset(name) {
      switch (name) {
        case 'legal_entity':
          this.openValueSetModal(appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST);
          break;
        case 'operating_unit':
          this.openValueSetModal(appStrings.VALUESETTYPE.OU_LIST);
          break;
        case 'mutation_type':
          this.openValueSetModal(appStrings.VALUESETTYPE.MUTATION_TYPE);
          break;
        case 'fms_project':
          this.openValueSetModal(appStrings.VALUESETTYPE.FMS_PROJECT);
          break;
        case 'fms_tower':
          this.openValueSetModal(appStrings.VALUESETTYPE.FMS_TOWER);
          break;
        case 'fms_floor':
          this.openValueSetModal(appStrings.VALUESETTYPE.FMS_FLOOR);
          break;
        case 'fms_unit':
          this.openValueSetModal(appStrings.VALUESETTYPE.FMS_UNIT);
          break;
        case 'transfer_status':
          this.openValueSetModal(appStrings.VALUESETTYPE.SCHEDULE_STATUS_VSET);
          break;
        case 'approval_status':
          this.openValueSetModal(appStrings.VALUESETTYPE.APPROVAL_STATUS);
          break;
      }
    },
    chooseParentVsetId(name) {
      switch (name) {
        case appStrings.VALUESETTYPE.OU_LIST:
        case appStrings.VALUESETTYPE.FMS_PROJECT:
          this.parent_value_set_id = this.leId;
          break;
        default:
          this.parent_value_set_id = null;
      }
    }
  },
  mounted() {}
};
